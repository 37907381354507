import React, { useEffect, useState, Component } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import FormValidator from '../FormValidator';
import { withRouter } from '../withRouter';
import swal from 'sweetalert2';
//import logo from 'src/assets/images/avatars/Logo02.png';
import logo from 'src/assets/images/avatars/adxlogo.png';
import 'src/scss/input_css.scss'
import 'src/scss/new_design.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

window.Swal = swal;
class VerifyOTP extends Component {

  constructor(props) {

    const resetPassword = sessionStorage.getItem('resetPassword');
    if (resetPassword) {
      Swal.fire('Sucess!', 'Your password successfully changed', 'success');
      sessionStorage.removeItem('resetPassword');
    }

    super(props);
    this.validator = new FormValidator([
     {
        field: 'otp',
        method: 'isEmpty',
        validWhen: false,
        message: 'The OTP field is required.'
      }
    ]);
    this.state = {
      otp: '',
      data: [],
      validation: this.validator.valid(),
    }

    this.submitted = false;
    this.isLoading = true;

  }
  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormSubmit = async event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({
      validation
    });
    this.submitted = true;
    if (validation.isValid) {
      //reaches here if form validates successfully... 
      let email = sessionStorage.getItem('password_reset_email');
      let otp = this.state.otp;
      let result = await fetch(window.apiFetchBaseURL+'auth/otp-verify', {
        method: 'post',
        body: JSON.stringify({
          email: email,
          otp: otp,
        }),
        headers: {
          "access-control-allow-origin": "*",
          'Content-Type': 'application/json'
        }
      });

      result = await result.json();
      console.warn(result)
      if (result.status == false) {      
        alert(result.message);
      }else if (result.status == true) {      
        sessionStorage.setItem('otp_verify', 1);            
        this.props.navigate("/reset-password");
      } else {
        alert("Something went wrong");
      }
    }
  }



  render() {

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    return (

      <div class="container">   
        <ToastContainer /> 
        <div class="row d-flex justify-content-center">
          <div class="col-sm-4 col-lg-4 col-xl-4 col-xs-12 user_card" style={{ "margin-top": "69px" }}>
            <div className='row justify-content-center' style={{"margin-top": "20px"}}>
              <img src={logo} class="Logo" alt="Logo" />
            </div>
            <div class="d-flex justify-content-center form_container">
              <div className='col-12'>
                <form>
                  <div class=" input-group mb-3" >
                    <div className="form-outline">
                      <input className={validation.otp.message || this.state.otp ? "form-control active " : " form-control "} type="text" id="otp" name='otp' onChange={this.handleChange} />
                      <label className={validation.otp.message ? "form-label label-color" : " form-label"} htmlFor="Username">OTP <span style={{ "color": "#c13818" }}>*</span></label>
                      <div className="form-notch">
                        <div className={validation.otp.message ? "form-notch-leading border-error-color" : "form-notch-leading"} style={{ "width": "9px" }}>    </div>
                        <div className={validation.otp.message ? "form-notch-middle border-error-color-midd" : "form-notch-middle"} style={{ "width": "40.4px" }}></div>
                        <div className={validation.otp.message ? "form-notch-trailing border-error-color" : "form-notch-trailing"} ></div>
                      </div>
                    </div>
                  </div>
                 

                  <div class="mt-4">                   
                    <div class="d-flex justify-content-center " >
                      <Link to="/login" class="Create-an-Account">Login</Link>
                    </div>
                  </div>


                  <div className="Actions-2 mt-3" onClick={this.handleFormSubmit}>
                    <div className="Web-Button">
                      <a style={{ "color": "white" }} className='register-button' >
                        Verify OTP
                      </a>
                    </div>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(VerifyOTP)
