function filterFunction() {
    // $("#myInput").focus();
    var input, filter, ul, li, a, i;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    div = document.getElementById("myDropdown");
    a = div.getElementsByTagName("a");
    for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = "";
        } else {
            a[i].style.display = "none";
        }
    }
}

//Device functions
$(document).ready(function () {

    $("#apply_widget").click(function () {
        $('#widget').modal('toggle');
        var targets = [];
        var count = 0;
        $.each($("#targets option:selected"), function () {
            targets.push($(this).text());

        });
        for (var i = 0; i < targets.length; ++i) {
            count++;
        }
        if (count > 1) {
            c1 = count - 1;
            // var img_device = document.getElementById("img-device").src;
            document.getElementById("camp_id").innerHTML = " <span class='sudo' id='camp_it' style='display:inline-flex;;margin-top:2px !important;' >" + targets[0] + " + " + c1 + " more " + "<div id='camp_close' class=' p-0 ml-1'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;'></i></div></span>";

            $('#camp_id').addClass('pl-2 list-btn-1');
            $('#camp_id').removeClass('text-black');
            $('#camp_id').prop('disabled', false);
            $('#camp_span').prop('hidden', false);

        } else if (count > 0) {
            document.getElementById("camp_id").innerHTML = " <span class='sudo' id='camp_it'  style='display:inline-flex;;margin-top:2px !important;'>" + targets[0] + "<div id='camp_close' class='ml-1 p-0'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;' ></i></div></span>";
            $('#camp_id').addClass('pl-2 list-btn-1');
            $('#camp_id').removeClass('text-black');
            $('#camp_id').prop('disabled', false);
            $('#camp_span').prop('hidden', false);

        }
        $("#camp_close").click(function (event) {
            $("#targets option:selected").removeAttr("selected");
            $("#ms-targets .ms-selection").find(".ms-list li.ms-selected").hide();
            $("#ms-targets .ms-selection").find(".ms-list li.ms-selected").removeClass('ms-selected');
            $("#ms-targets .ms-selectable").find(".ms-list li.ms-hover").show();
            $("#ms-targets .ms-selectable").find(".ms-list li.ms-hover").removeClass('ms-selected');
            $("#ms-targets .ms-selectable").find(".ms-list li.ms-hover").removeClass('ms-hover');
            setTimeout(function () {
                $('#widget').hide();
                // $('#camp_span').prop('hidden', true);
                $("#camp_id").removeClass('pl-2 list-btn-1 btn-rounded');
                $("#camp_id").removeClass('btn-default');
                document.getElementById("camp_id").innerHTML = "All";
                $('#filterBtn').trigger('click');
                $('#reportsdate').trigger('click');
                $('.cancelBtn ').trigger('click');
            }, 100);
            event.stopImmediatePropagation();
            unsetfilter(1);
        })
        $("#camp_it").click(function (event) {
            $('#widget').modal('toggle');
            event.stopImmediatePropagation();
        })
    });


    //Sites filter
    $("#apply_sites").click(function () {
        $('#sitestype').modal('toggle');
        var sites = [];
        var count = 0;
        $.each($("#sites option:selected"), function () {
            sites.push($(this).text());

        });
        for (var i = 0; i < sites.length; ++i) {
            count++;
        }
        if (count > 1) {
            c1 = count - 1;
            // var img_device = document.getElementById("img-device").src;
            document.getElementById("site_id").innerHTML = " <span class='sudo' id='site_id' style='display:inline-flex;;margin-top:2px !important;' >" + sites[0] + " + " + c1 + " more " + "<div id='site_close' class=' p-0 ml-1'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;'></i></div></span>";

            $('#site_id').addClass('pl-2 list-btn-1');
            $('#site_id').removeClass('text-black');
            $('#site_id').prop('disabled', false);
            $('#site_span').prop('hidden', false);

        } else if (count > 0) {
            document.getElementById("site_id").innerHTML = " <span class='sudo' id='site_id'  style='display:inline-flex;;margin-top:2px !important;'>" + sites[0] + "<div id='site_close' class='ml-1 p-0'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;' ></i></div></span>";
            $('#site_id').addClass('pl-2 list-btn-1');
            $('#site_id').removeClass('text-black');
            $('#site_id').prop('disabled', false);
            $('#site_span').prop('hidden', false);

        }
        $("#site_close").click(function (event) {
            $("#sites option:selected").removeAttr("selected");
            $("#ms-sites .ms-selection").find(".ms-list li.ms-selected").hide();
            $("#ms-sites .ms-selection").find(".ms-list li.ms-selected").removeClass('ms-selected');
            $("#ms-sites .ms-selectable").find(".ms-list li.ms-hover").show();
            $("#ms-sites .ms-selectable").find(".ms-list li.ms-hover").removeClass('ms-selected');
            $("#ms-sites .ms-selectable").find(".ms-list li.ms-hover").removeClass('ms-hover');
            setTimeout(function () {
                $('#sitestype').hide();
                $('#site_span').prop('hidden', true);
                $("#site_id").removeClass('pl-2 list-btn-1 btn-rounded');
                $("#site_id").removeClass('btn-default');
                document.getElementById("site_id").innerHTML = "All";
                $('#filterBtn').trigger('click');
                $('#reportsdate').trigger('click');
                $('.cancelBtn ').trigger('click');
            }, 100);
            event.stopImmediatePropagation();
            unsetfilter(2);
        })
        $("#site_id").click(function (event) {
            $('#sitestype').modal('toggle');
            event.stopImmediatePropagation();
        })
    });
    //end site filter



});


function abc(){
    alert("dsfds");
}

$(document).ready(function () {
    $("#apply_devices").on('click', function () {
        //$('#devicetype').modal('toggle');
        var devicetype = [];
        var count = 0;
        $.each($("#devicetype option:selected"), function () {
            devicetype.push($(this).text());

        });
        for (var i = 0; i < devicetype.length; ++i) {
            count++;
        }
        if (count > 1) {
            c1 = count - 1;

            document.getElementById("device_id").innerHTML = " <span class='sudo' id='device_it' style='display:inline-flex;margin-top:2px !important;' >" + devicetype[0] + " + " + c1 + " more " + "<div id='device_close' class=' p-0 ml-1'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;'></i></div></span>";


            $('#device_id').addClass('pl-2 list-btn-1');
            $('#device_id').removeClass('text-black');
            $('#device_id').prop('disabled', false);
            $('#device_span').prop('hidden', false);


        } else {
            document.getElementById("device_id").innerHTML = " <span class='sudo' id='device_it'  style='display:inline-flex;;margin-top:2px !important;'>" + devicetype[0] + "<div id='device_close' class='ml-1 p-0'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;' ></i></div></span>";
            $('#device_id').addClass('pl-2 list-btn-1');
            $('#device_id').removeClass('text-black');
            $('#device_id').prop('disabled', false);
            $('#device_span').prop('hidden', false);

        }
        console.log(devicetype[0]);
        if (devicetype[0] == 'undefined') {

            //document.getElementById("device_id").innerHTML = "All";
            $('#devicetype').hide();
            $('#device_span').prop('hidden', true);
            event.stopImmediatePropagation();
        }

        $("#device_close").click(function (event) {
            $('#devicetype').hide();
            $('#device_span').prop('hidden', true);
            event.stopImmediatePropagation();
        })
        $("#device_it").click(function (event) {
            $('#devicetype').modal('toggle');
            event.stopImmediatePropagation();
        })
    });
});


//browser functions
$(document).ready(function () {

    $("#apply_browsers").click(function () {
        $('#browsertype').modal('toggle');
        var browsertype = [];
        var count = 0;
        $.each($("#browsertype option:selected"), function () {
            browsertype.push($(this).text());

        });
        for (var i = 0; i < browsertype.length; ++i) {
            count++;
        }
        if (count > 1) {
            c1 = count - 1;
            // var img_device = document.getElementById("img-device").src;
            document.getElementById("browser_id").innerHTML = " <span class='sudo' id='bt_id' style='display:inline-flex;;margin-top:2px !important;' >" + browsertype[0] + " + " + c1 + " more " + "<div id='browser_close' class=' p-0 ml-1'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;'></i></div></span>";

            $('#browser_id').addClass('pl-2 list-btn-1');
            $('#browser_id').removeClass('text-black');
            $('#browser_id').prop('disabled', false);
            $('#browser_span').prop('hidden', false);

        } else {
            document.getElementById("browser_id").innerHTML = " <span class='sudo' id='bt_id'  style='display:inline-flex;;margin-top:2px !important;'>" + browsertype[0] + "<div id='browser_close' class='ml-1 p-0'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;' ></i></div></span>";
            $('#browser_id').addClass('pl-2 list-btn-1');
            $('#browser_id').removeClass('text-black');
            $('#browser_id').prop('disabled', false);
            $('#browser_span').prop('hidden', false);

        }
        $("#browser_close").click(function (event) {
            $('#browsertype').hide();
            $('#browser_span').prop('hidden', true);
            event.stopImmediatePropagation();
        })
        $("#bt_id").click(function (event) {
            $('#browsertype').modal('toggle');
            event.stopImmediatePropagation();
        })
    });

    // });
    // // interest type fundcionts
    // $(document).ready(function() {



    $("#apply_interests").click(function () {
        $('#interesttype').modal('toggle');
        var interesttype = [];
        var count = 0;
        $.each($("#interesttype option:selected"), function () {
            interesttype.push($(this).text());

        });
        for (var i = 0; i < interesttype.length; ++i) {
            count++;
        }
        if (count > 1) {
            c1 = count - 1;
            // var img_device = document.getElementById("img-device").src;
            document.getElementById("interest_id").innerHTML = " <span class='sudo' id='it_id' style='display:inline-flex;;margin-top:2px !important;' >" + interesttype[0] + " + " + c1 + " more " + "<div id='interest_close' class=' p-0 ml-1'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;'></i></div></span>";
            $('#interest_id').addClass('pl-2 list-btn-1');
            $('#interest_id').removeClass('text-black');
            $('#interest_id').prop('disabled', false);
            $('#interest_span').prop('hidden', false);

        } else {
            document.getElementById("interest_id").innerHTML = " <span class='sudo' id='it_id'  style='display:inline-flex;;margin-top:2px !important;'>" + interesttype[0] + "<div id='interest_close' class='ml-1 p-0'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;' ></i></div></span>";
            $('#interest_id').addClass('pl-2 list-btn-1');
            $('#interest_id').removeClass('text-black');
            $('#interest_id').prop('disabled', false);
            $('#interest_span').prop('hidden', false);

        }
        $("#interest_close").click(function (event) {
            $('#interesttype').hide();
            $('#interest_span').prop('hidden', true);
            event.stopImmediatePropagation();
        })
        $("#it_id").click(function (event) {
            $('#interesttype').modal('toggle');
            event.stopImmediatePropagation();
        })
    });

    // });
    // // location type fundcionts
    // $(document).ready(function() {
    $("#apply_location").click(function () {
        $('#location').modal('toggle');
        var location = [];
        var count = 0;
        $.each($("#location option:selected"), function () {
            location.push($(this).text());

        });
        for (var i = 0; i < location.length; ++i) {
            count++;
        }
        if (count > 1) {
            c1 = count - 1;
            // var img_device = document.getElementById("img-device").src;
            document.getElementById("location_id").innerHTML = " <span class='sudo' id='location_it' style='display:inline-flex;;margin-top:2px !important;' >" + location[0] + " + " + c1 + " more " + "<div id='location_close' class=' p-0 ml-1'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;'></i></div></span>";
            $('#location_id').addClass('pl-2 list-btn-1');
            $('#location_id').removeClass('text-black');
            $('#location_id').prop('disabled', false);
            $('#location_span').prop('hidden', false);

        } else {
            document.getElementById("location_id").innerHTML = " <span class='sudo' id='location_it'  style='display:inline-flex;;margin-top:2px !important;'>" + location[0] + "<div id='location_close' class='ml-1 p-0'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;' ></i></div></span>";
            $('#location_id').addClass('pl-2 list-btn-1');
            $('#location_id').removeClass('text-black');
            $('#location_id').prop('disabled', false);
            $('#location_span').prop('hidden', false);

        }
        $("#location_close").click(function (event) {
            $('#location').hide();
            $('#location_span').prop('hidden', true);
            event.stopImmediatePropagation();
        })
        $("#location_it").click(function (event) {
            $('#location').modal('toggle');
            event.stopImmediatePropagation();
        })
    });

    // });

    // // OS type fundcionts
    // $(document).ready(function() {
    $("#apply_os").click(function () {
        $('#os_type').modal('toggle');
        var os_type = [];
        var count = 0;
        $.each($("#os_type option:selected"), function () {
            os_type.push($(this).text());

        });
        for (var i = 0; i < os_type.length; ++i) {
            count++;
        }
        if (count > 1) {
            c1 = count - 1;
            // var img_device = document.getElementById("img-device").src;
            document.getElementById("os_id").innerHTML = " <span class='sudo' id='os_it' style='display:inline-flex;;margin-top:2px !important;' >" + os_type[0] + " + " + c1 + " more " + "<div id='os_close' class=' p-0 ml-1'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;'></i></div></span>";
            $('#os_id').addClass('pl-2 list-btn-1');
            $('#os_id').removeClass('text-black');
            $('#os_id').prop('disabled', false);
            $('#os_span').prop('hidden', false);

        } else {
            document.getElementById("os_id").innerHTML = " <span class='sudo' id='os_it'  style='display:inline-flex;;margin-top:2px !important;'>" + os_type[0] + "<div id='os_close' class='ml-1 p-0'><i class=' fas fa-times-circle ' style='font-size: 15px;vertical-align: middle;' ></i></div></span>";
            $('#os_id').addClass('pl-2 list-btn-1');
            $('#os_id').removeClass('text-black');
            $('#os_id').prop('disabled', false);
            $('#os_span').prop('hidden', false);

        }
        $("#os_close").click(function (event) {
            $('#os_type').hide();
            $('#os_span').prop('hidden', true);
            event.stopImmediatePropagation();
        })
        $("#os_it").click(function (event) {
            $('#os_type').modal('toggle');
            event.stopImmediatePropagation();
        })
    });

});