import React, { useEffect, useState, Component } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import FormValidator from '../FormValidator';
import { withRouter } from '../withRouter';
import swal from 'sweetalert2';
//import logo from 'src/assets/images/avatars/Logo02.png';
import logo from 'src/assets/images/avatars/adxlogo.png';
import 'src/scss/input_css.scss'
import 'src/scss/new_design.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

window.Swal = swal;
class ResetPassword extends Component {

  constructor(props) {


    super(props);
    this.validator = new FormValidator([
     {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Password field is required.'
      }, {
        field: 'confirmpassword',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Password confirmation field is required.'
      }, {
        field: 'confirmpassword',
        method: this.passwordMatch, // notice that we are passing a custom function here
        validWhen: true,
        message: 'Password and password confirmation do not match.'
      }
    ]);
    this.state = {
      password: '',
      confirmpassword: '',
      data: [],
      validation: this.validator.valid(),
    }

    this.submitted = false;
    this.isLoading = true;

  }
  passwordMatch = (confirmation, state) => (state.password === confirmation)
  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }


  handleFormSubmit = async event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({
      validation
    });
    this.submitted = true;
    if (validation.isValid) {
      //reaches here if form validates successfully... 
      let password = this.state.password;
      let confirmpassword = this.state.confirmpassword;
      let email = sessionStorage.getItem('password_reset_email');
      let result = await fetch(window.apiFetchBaseURL+'auth/reset-password', {
        method: 'post',
        body: JSON.stringify({
          password: password,
          confirm_password: confirmpassword,
          email: email,
        }),
        headers: {
          "access-control-allow-origin": "*",
          'Content-Type': 'application/json'
        }
      });

      result = await result.json();
      if (result.status == true) {
        sessionStorage.clear('password_reset_email');         
        sessionStorage.clear('otp_verify');   
        sessionStorage.setItem('resetPassword',true);        
        this.props.navigate("/login");

      } else {
        alert("Email does not exist.");
      }
    }
  }


  render() {

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    return (

      <div class="container">   
        <ToastContainer /> 
        <div class="row d-flex justify-content-center">
          <div class="col-sm-4 col-lg-4 col-xl-4 col-xs-12 user_card" style={{ "margin-top": "69px" }}>
            <div className='row justify-content-center' style={{"margin-top": "20px"}}>
              <img src={logo} class="Logo" alt="Logo" />
            </div>
            <div class="d-flex justify-content-center form_container">
              <div className='col-12'>
                <form>
                  <div class=" input-group mb-3" >
                    <div className="form-outline">
                      <input className={validation.password.message || this.state.password ? "form-control active " : " form-control "} type="text" id="password" name='password' onChange={this.handleChange} />
                      <label className={validation.password.message ? "form-label label-color" : " form-label"} htmlFor="Username">Password <span style={{ "color": "#c13818" }}>*</span></label>
                      <div className="form-notch">
                        <div className={validation.password.message ? "form-notch-leading border-error-color" : "form-notch-leading"} style={{ "width": "9px" }}>    </div>
                        <div className={validation.password.message ? "form-notch-middle border-error-color-midd" : "form-notch-middle"} style={{ "width": "65.4px" }}></div>
                        <div className={validation.password.message ? "form-notch-trailing border-error-color" : "form-notch-trailing"} ></div>
                      </div>
                    </div>
                  </div>
                  <div class=" input-group mb-3" >
                    <div className="form-outline">
                      <input className={validation.confirmpassword.message || this.state.confirmpassword ? "form-control active " : " form-control "} type="text" id="confirmpassword" name='confirmpassword' onChange={this.handleChange} />
                      <label className={validation.confirmpassword.message ? "form-label label-color" : " form-label"} htmlFor="Username">Confirm Password <span style={{ "color": "#c13818" }}>*</span></label>
                      <div className="form-notch">
                        <div className={validation.confirmpassword.message ? "form-notch-leading border-error-color" : "form-notch-leading"} style={{ "width": "9px" }}>    </div>
                        <div className={validation.confirmpassword.message ? "form-notch-middle border-error-color-midd" : "form-notch-middle"} style={{ "width": "115.4px" }}></div>
                        <div className={validation.confirmpassword.message ? "form-notch-trailing border-error-color" : "form-notch-trailing"} ></div>
                      </div>                      
                    </div>                    
                  </div>
                 {validation.confirmpassword.message && <p className="ErrorColor">{validation.confirmpassword.message}</p>}

                  <div class="mt-4">                   
                    <div class="d-flex justify-content-center " >
                      <Link to="/login" class="Create-an-Account">Login</Link>
                    </div>
                  </div>


                  <div className="Actions-2 mt-3" onClick={this.handleFormSubmit}>
                    <div className="Web-Button">
                      <a style={{ "color": "white" }} className='register-button' >
                        Submit
                      </a>
                    </div>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ResetPassword)
