import $ from 'jquery';
import axios from "axios";
import Swal from 'sweetalert2';
//const navigate = useNavigate();
var ct;
const seconds = "secs";
function startcountDown() {
    clearInterval(ct);
    var countDownSeconds = 30;
    ct = setInterval(function () {
        if (countDownSeconds < 0) {
            clearInterval(ct);
            $('.selected-pay').trigger('click');

        } else {
            $('#counter30').text('00:' + countDownSeconds + ' ' + seconds);
        }
        countDownSeconds--;
    }, 1000);
}
$(document).on('click', '#btn-visa', function () {
    clearInterval(ct);
    $('#counter30').text('00:30' + ' ' + seconds);
    $('#credit_card').show();
    $('#wiretransfer').hide();
    $('#bitcoin').hide();
    $('#paypal').hide();
    $('#erc20').hide();
    $('#trc20').hide();
    $('#btn-erc20').removeClass('selected-pay');
    $('#btn-trc20').removeClass('selected-pay');
    $('#btn-visa').addClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-bitcoin').removeClass('selected-pay');
    $('#btn-paystack').removeClass('selected-pay');
    $('#counter30').hide(); //Counter 30s hidden

    $("#cardd").attr('checked', true);




    $(".cardRadio").attr('hidden', true);
    $(".cardRadioInput").attr('hidden', false);  

    $("#btn-visa .cardRadioInput").attr('hidden', true);    
    $("#btn-visa .cardRadio").attr('hidden', false);

});

const  paypalDataset= async()=>{
  

    try {
        if ($('#amount_select').val() == 'other') {
            var amount = $('#amount_other').val();
        } else {
            var amount = $('#amount_select').val();
        }
    
        const data = {
            usd: amount,   
        };

        let token = localStorage.getItem('user');
        const response = await axios.post(window.apiFetchBaseURL + 'funds/paypalDataset', data, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        });
        //console.warn("response",response.data)
        var dt = response.data;      
        if (dt?.status === 1) {                
            //$('#paypalpay').attr('href', window.apiFetchBaseURL+dt.data.redirect_url);
            $('#amt2').text('$' + dt.data.card_amount);
            $('#process_fees2').text(dt.data.fees + '%');
            $('#vat_value2').text(dt.data.vat + '%');
            $('#totala2').text('$' + dt.data.total_amount);
            $('#paypalpay').removeClass('isnotactive');
        } else {
            $('#paypalpay').attr('href', '#');
            $('#card_amout1').text('$0');
            $('#process_fees2').text('0%');
            $('#vat_value1').text('0%');
            $('#totala1').text('$0');
            $('#paypalpay').addClass('isnotactive');
        }
        
    } catch (err) {
        console.log(err);
        if (axios?.isCancel(err)) {
            console.log("axios request cancelled");
        } else {
            if (err?.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    html: "There was an error communicating with the server! try again",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else if (err?.response?.status === 404) {
                Swal.fire({
                    icon: "error",
                    html: "An error occurred! Please try again",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else if (err?.response?.status === 401) {
                Swal.fire({
                    icon: "error",
                    html: "An error occurred! Please try again",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    html: "There was an error communicating with the server! Try again.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }
    }
}
$(document).on('click', '#btn-paypal', function () {
    clearInterval(ct);
    $('#counter30').text('00:30' + ' ' + seconds);
    $('#credit_card').hide();
    $('#wiretransfer').hide();
    $('#bitcoin').hide();
    $('#erc20').hide();
    $('#trc20').hide();
    $('#paypal').show();
    $('#btn-erc20').removeClass('selected-pay');
    $('#btn-trc20').removeClass('selected-pay');
    $('#btn-paypal').addClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');
    $('#btn-bitcoin').removeClass('selected-pay');
    $('#btn-paystack').removeClass('selected-pay');
    $('#counter30').hide(); //Counter 30s hidden

    $("#paypall").attr('checked', true);


    $(".cardRadio").attr('hidden', true);
    $(".cardRadioInput").attr('hidden', false);  

    $("#btn-paypal .cardRadioInput").attr('hidden', true);    
    $("#btn-paypal .cardRadio").attr('hidden', false);

    paypalDataset()
    return false;
});

$(document).on('click', '#btn-paystack', function () {
    clearInterval(ct);
    $('#counter30').text('00:30' + ' ' + seconds);
    $('#credit_card').hide();
    $('#wiretransfer').hide();
    $('#bitcoin').hide();
    $('#paypal').hide();
    $('#erc20').hide();
    $('#trc20').hide();
    $('#paystack').show();
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-paystack').addClass('selected-pay');
    $('#btn-trc20').removeClass('selected-pay');
    $('#btn-erc20').removeClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');
    $('#btn-bitcoin').removeClass('selected-pay');
    $('#counter30').hide(); //Counter 30s hidden
});

$(document).on('click', '#btn-erc20', function () {
    $('#credit_card').hide();
    $('#wiretransfer').hide();
    $('#bitcoin').hide();
    $('#paypal').hide();
    $('#counter30').show(); //Counter 30s visible
    $('#trc20').hide();
    $('#erc20').show();
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-erc20').addClass('selected-pay');
    $('#btn-trc20').removeClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');
    $('#btn-bitcoin').removeClass('selected-pay');
});

$(document).on('click', '#btn-trc20', function () {
    $('#credit_card').hide();
    $('#wiretransfer').hide();
    $('#bitcoin').hide();
    $('#paypal').hide();
    $('#erc20').hide();
    $('#trc20').show();
    $('#counter30').show(); //Counter 30s visible
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-trc20').addClass('selected-pay');
    $('#btn-erc20').removeClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');
    $('#btn-bitcoin').removeClass('selected-pay');
});
$('#amount_other').on('blur input', function () {
    $('.selected-pay').trigger('click');
});

$(document).on('click', '#verifyhash', function () {
    $('#getbtchashval').html('');
    $('#successhash').addClass('hider');
    $('.bitwalleterror').addClass('hider');
    $('.senderhasherror').addClass('hider');
    if ($('#senderhash').val() == '') {
        $('#senderhash').focus();
        $('.senderhasherror').removeClass('hider');
        return false;
    } else if ($('#bitwallet').val() == '') {
        $('#bitwallet').focus();
        $('.bitwalleterror').removeClass('hider');
        return false;
    } else {
        $('.bitwalleterror').addClass('hider');
        $(".nav-fixed").addClass('onrequest');
        $(".dot-flashing").show();
        $.get(window.apiFetchBaseURL + 'funds/getbtchashval', {
            hash: $('#bitwallet').val(),
            senderhash: $('#senderhash').val()
        }, function (data) {
            var dt = JSON.parse(data);
            if (dt.status == 1) {
                $('#successhash').removeAttr('disabled');
                $('#successhash').removeClass('hider');
                $('#getbtchashval').html(dt.pagedata); //'<table class="table dataTable table-bordered" style="border-radius:8px !important;align-self: center;"><tbody><tr class=""><td class="text-dark font-weight-600 text-left">Sender&#39;s Transaction Hash</td><td class="text-dark font-weight-600 text-left">' + dt.title + '</td></tr><tr><td class="text-dark font-weight-600 text-left">Status of Payment</td><td class="text-success text-left " style="color:green !important;">Confirmed</td></tr><tr><td class="text-dark font-weight-600 text-left">Amount</td><td class="text-left">' + dt.amount + ' </td></tr></tbody></table>');
            } else if (dt.status == 2) {
                $('#getbtchashval').html(dt.pagedata).css('color', 'red');
            } else {
                $('#successhash').attr('disabled', true);
                $('#successhash').addClass('hider');
                $('#getbtchashval').html(dt.pagedata); //'<table class="table dataTable table-bordered" style="border-radius:8px !important;align-self: center;"><tbody><tr class=""><td class="text-dark font-weight-600 text-left">Sender&#39;s Transaction Hash</td><td class="text-dark font-weight-600 text-left">' + dt.title + '</td></tr><tr><td class="text-dark font-weight-600 text-left">Status of Payment</td><td class="text-red text-left " style="color:red !important;">Unconfirmed</td></tr><tr><td class="text-dark font-weight-600 text-left">Amount</td><td class="text-left">' + dt.amount + '</td></tr></tbody></table>');
            }
            $(".dot-flashing").hide();
            $(".nav-fixed").removeClass('onrequest');
        })
    }
});

$(document).on('click', '#successhash', function () {
    $(".nav-fixed").addClass('onrequest');
    $(".dot-flashing").show();
    $.get(window.apiFetchBaseURL + 'funds/bitcoinsTransSave', {},
        function (data) {
            var dt = JSON.parse(data);
            if (dt.status > 0) {
                alert(dt.msg);
                window.location.href = dt.url;
            } else {
                $(".dot-flashing").hide();
                $(".nav-fixed").removeClass('onrequest');
                alert(dt.msg);
            }
        })
});

$('#successhashERC').on('click', function () {
    $(".nav-fixed").addClass('onrequest');
    $(".dot-flashing").show();
    $.get(window.apiFetchBaseURL + 'funds/usdtERC20TransSave', {},
        function (data) {
            var dt = JSON.parse(data);
            if (dt.status > 0) {
                alert(dt.msg);
                window.location.href = dt.url;
            } else {
                $(".dot-flashing").hide();
                $(".nav-fixed").removeClass('onrequest');
                alert(dt.msg);
            }
        })
});

$(document).on('click', '#successhashTRC', function () {
    $(".nav-fixed").addClass('onrequest');
    $(".dot-flashing").show();
    $.get(window.apiFetchBaseURL + 'funds/usdtTRC20TransSave', {},
        function (data) {
            var dt = JSON.parse(data);
            if (dt.status > 0) {
                alert(dt.msg);
                window.location.href = dt.url;
            } else {
                $(".dot-flashing").hide();
                $(".nav-fixed").removeClass('onrequest');
                alert(dt.msg);
            }
        })
});

$(document).on('click', '#verifyUSDTERChash', function () {
    $('#geterchashval').html('');
    $('#successhashERC').addClass('hider');
    $('.bitwalleterror').addClass('hider');
    $('.senderhasherror').addClass('hider');
    $('#successhashERC').removeAttr('disabled');
    $('#successhashERC').addClass('hider');
    if ($('#senderhash').val() == '') {
        $('#senderhash').focus();
        $('.senderhasherror').removeClass('hider');
        return false;
    } else if ($('#bitwallet').val() == '') {
        $('#bitwallet').focus();
        $('.bitwalleterror').removeClass('hider');
    } else {
        $('.bitwalleterror').addClass('hider');
        $.get(window.apiFetchBaseURL + 'funds/getERChashval', {
            hash: $('#bitwallet').val(),
            senderhash: $('#senderhash').val()
        }, function (data) {
            var dt = JSON.parse(data);
            if (dt.status == 1) {
                $('#successhashERC').removeAttr('disabled');
                $('#successhashERC').removeClass('hider');
                $('#geterchashval').html(dt.pagedata); //'<table class="table dataTable table-bordered" style="border-radius:8px !important;align-self: center;"><tbody><tr class=""><td class="text-dark font-weight-600 text-left">Sender&#39;s Transaction Hash</td><td class="text-dark font-weight-600 text-left">' + dt.title + '</td></tr><tr><td class="text-dark font-weight-600 text-left">Status of Payment</td><td class="text-success text-left " style="color:green !important;">Confirmed</td></tr><tr><td class="text-dark font-weight-600 text-left">Amount</td><td class="text-left">' + dt.amount + ' </td></tr></tbody></table>');

            } else if (dt.status == 2) {
                $('#geterchashval').html(dt.pagedata).css('color', 'red');
            } else {
                $('#successhashERC').attr('disabled', true);
                $('#successhashERC').addClass('hider');
                $('#geterchashval').html(dt.pagedata); //'<table class="table dataTable table-bordered" style="border-radius:8px !important;align-self: center;"><tbody><tr class=""><td class="text-dark font-weight-600 text-left">Sender&#39;s Transaction Hash</td><td class="text-dark font-weight-600 text-left">' + dt.title + '</td></tr><tr><td class="text-dark font-weight-600 text-left">Status of Payment</td><td class="text-red text-left " style="color:red !important;">Unconfirmed</td></tr><tr><td class="text-dark font-weight-600 text-left">Amount</td><td class="text-left">' + dt.amount + '</td></tr></tbody></table>');
            }
        })
    }
});

$(document).on('click', '#verifyUSDTTRChash', function () {
    $('#gettrchashval').html('');
    $('.senderhasherror').addClass('hider');
    $('#successhashTRC').addClass('hider');
    if ($('#senderhash').val() == '') {
        $('#senderhash').focus();
        $('.senderhasherror').removeClass('hider');
        return false;
    } else if ($('#bitwallet').val() == '') {
        $('#bitwallet').focus();
        $('.bitwalleterror').removeClass('hider');
    } else {
        $('.bitwalleterror').addClass('hider');
        $.get(window.apiFetchBaseURL + 'funds/getTRChashval', {
            hash: $('#bitwallet').val(),
            senderhash: $('#senderhash').val()
        }, function (data) {
            var dt = JSON.parse(data);
            if (dt.status == 1) {
                $('#successhashTRC').removeAttr('disabled');
                $('#successhashTRC').removeClass('hider');
                $('#gettrchashval').html(dt.pagedata); //'<table class="table dataTable table-bordered" style="border-radius:8px !important;align-self: center;"><tbody><tr class=""><td class="text-dark font-weight-600 text-left">Sender&#39;s Transaction Hash</td><td class="text-dark font-weight-600 text-left">' + dt.title + '</td></tr><tr><td class="text-dark font-weight-600 text-left">Status of Payment</td><td class="text-success text-left " style="color:green !important;">Confirmed</td></tr><tr><td class="text-dark font-weight-600 text-left">Amount</td><td class="text-left">' + dt.amount + ' </td></tr></tbody></table>');
            } else if (dt.status == 2) { //hash key incorrect
                $('#gettrchashval').html(dt.pagedata).css('color', 'red');
            } else {
                $('#successhashTRC').attr('disabled', true);
                $('#successhashTRC').addClass('hider');
                $('#gettrchashval').html(dt.pagedata); //'<table class="table dataTable table-bordered" style="border-radius:8px !important;align-self: center;"><tbody><tr class=""><td class="text-dark font-weight-600 text-left">Sender&#39;s Transaction Hash</td><td class="text-dark font-weight-600 text-left">' + dt.title + '</td></tr><tr><td class="text-dark font-weight-600 text-left">Status of Payment</td><td class="text-red text-left " style="color:red !important;">Unconfirmed</td></tr><tr><td class="text-dark font-weight-600 text-left">Amount</td><td class="text-left">' + dt.amount + '</td></tr></tbody></table>');
            }
        })
    }
});

$(document).on('click', '#btn-bitcoin', function () {
    clearInterval(ct);
    if ($('#amount_select').val() == 'other') {
        var amount = parseInt($('#amount_other').val());
    } else {
        var amount = parseInt($('#amount_select').val());
    }
    var loader = $('.bitcoinrequest').html();
    $('.bitcoinrequest').html('');
    $('.bitcoinrequest').addClass('btnloader');
    $('.bitcoinrequest').html('<div class="d-inline-flex justify-content-between "><div class="dot-flashing" style="float:left;"></div></div>');

    const data = {
        usd: amount
    };
    let token = localStorage.getItem('user');
    fetch(window.apiFetchBaseURL + 'funds/getbitcoinval', {
        method: 'post',
        async: false,
        data: data,
        headers: {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((response) => response.json())
        .then(data => {          
            var dt = data;
            if (dt.status > 0) {
                $('#amt4').text('$' + amount);
                $('.bitcoinval').text('');
                let resultdata = (((dt.btc).toFixed(20)) * parseFloat(dt.calcamount)).toFixed(5);
                localStorage.setItem('btcval', resultdata);
                localStorage.setItem('btcsubtotal', dt.calcamount);
                $('.bitcoinval').text(resultdata);
                $('.subtotalbtc').text('$' + dt.calcamount);
                $('.subtotal').val(dt.calcamount);
                $('.dolleramount').val(amount);
                $('.btcamount').val(resultdata);
                $('.bitcoinrequest').html(loader);
                $('.bitcoinrequest').removeClass('btnloader');

            } else {
                $('.bitcoinrequest').html(loader);
                $('.bitcoinrequest').removeClass('btnloader');
                $('.bitcoinval').text('');
            }


            startcountDown();
            

        });

    $('#credit_card').hide();
    $('#wiretransfer').hide();
    $('#paypal').hide();
    $('#paystack').hide();
    $('#erc20').hide();
    $('#trc20').hide();
    $('#bitcoin').show();
    $('#btn-bitcoin').addClass('selected-pay');
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-erc20').removeClass('selected-pay');
    $('#btn-trc20').removeClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-paystack').removeClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');
    $('#counter30').show(); //Counter 30s visible
    let utll = window.location.href;
    let addfundurl = utll.split('funds');
    let href = addfundurl[0] + 'funds/requestasset';
    $('form').attr('action', '');
    $('form').attr('action', href);
    $("#bitcoinn").attr('checked', true);

    $(".cardRadio").attr('hidden', true);
    $(".cardRadioInput").attr('hidden', false);  

    $("#btn-bitcoin .cardRadioInput").attr('hidden', true);    
    $("#btn-bitcoin .cardRadio").attr('hidden', false);
    return false;
});

$(document).on('click', '#btn-erc20', function () {
    clearInterval(ct);
    if ($('#amount_select').val() == 'other') {
        var amount = parseInt($('#amount_other').val());
    } else {
        var amount = parseInt($('#amount_select').val());
    }
    var loader2 = $('.ercrequest').html();
    $('.ercrequest').html('');
    $('.ercrequest').addClass('btnloader');
    $('.ercrequest').html('<div class="d-inline-flex justify-content-between "><div class="dot-flashing" style="float:left;"></div></div>');
    const data = {
        usd: amount
    };
    let token = localStorage.getItem('user');
    fetch(window.apiFetchBaseURL + 'funds/getERCval', {
        method: 'post',
        data: data,
        headers: {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((response) => response.json())
        .then(data => {
            console.warn("data=", data)
            var dt = data;

            if (dt.status > 0) {
                $('#ercamt4').text('$' + amount);
                $('.ercval').text('');
                let resultdata = (((dt.erc).toFixed(20)) * parseFloat(dt.calcamount)).toFixed(5);

                $('.ercval').text(resultdata);
                localStorage.setItem('ercval', resultdata);
                localStorage.setItem('ercsubtotal', dt.calcamount);
                $('.subtotalerc').text('$' + dt.calcamount);
                $('.subtotal').val(dt.calcamount);
                $('.dolleramount').val(amount);
                $('.ercamount').val(resultdata);
                $('.ercrequest').html(loader2);
                $('.ercrequest').removeClass('btnloader');
            } else {
                $('.ercrequest').html(loader2);
                $('.ercrequest').removeClass('btnloader');
                $('.ercval').text('');
            }
            startcountDown();
        });

    $('#credit_card').hide();
    $('#wiretransfer').hide();
    $('#paypal').hide();
    $('#paystack').hide();
    $('#bitcoin').hide();
    $('#trc20').hide();
    $('#erc20').show();
    $('#counter30').show(); //Counter 30s visible
    $('#btn-bitcoin').removeClass('selected-pay');
    $('#btn-erc20').addClass('selected-pay');
    $('#btn-trc20').removeClass('selected-pay');
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-paystack').removeClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');

    let utll = window.location.href;
    let addfundurl = utll.split('funds');
    let href = addfundurl[0] + 'funds/requestasseterc';
    $('form').attr('action', '');
    $('form').attr('action', href);
    $("#btnErcc").attr('checked', true);

    $(".cardRadio").attr('hidden', true);
    $(".cardRadioInput").attr('hidden', false);  

    $("#btn-erc20 .cardRadioInput").attr('hidden', true);    
    $("#btn-erc20 .cardRadio").attr('hidden', false);
     return false;

});

$(document).on('click', '#btn-trc20', function () {
    clearInterval(ct);
    if ($('#amount_select').val() == 'other') {
        var amount = parseInt($('#amount_other').val());
    } else {
        var amount = parseInt($('#amount_select').val());
    }
    var loader3 = $('.trcrequest').html();
    $('.trcrequest').html('');
    $('.trcrequest').addClass('btnloader');
    $('.trcrequest').html('<div class="d-inline-flex justify-content-between "><div class="dot-flashing" style="float:left;"></div></div>');
    const data = {
        usd: amount
    };
    let token = localStorage.getItem('user');
    fetch(window.apiFetchBaseURL + 'funds/getTRCval', {
        method: 'post',
        data: data,
        headers: {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((response) => response.json())
        .then(data => {
            console.warn("data=", data)
            var dt = data;

            if (dt.status > 0) {
                $('#trcamt4').text('$' + amount);
                $('.trcval').text('');
                let resultdata = (((dt.trc).toFixed(20)) * parseFloat(dt.calcamount)).toFixed(5);

                $('.trcval').text(resultdata);
                $('.subtotaltrc').text('$' + dt.calcamount);
                localStorage.setItem('trcval', resultdata);
                localStorage.setItem('trcsubtotal', dt.calcamount);
                $('.dolleramount').val(amount);
                $('.subtotal').val(dt.calcamount);
                $('.trcamount').val(resultdata);
                $('.trcrequest').html(loader3);
                $('.trcrequest').removeClass('btnloader');
            } else {
                $('.trcval').text('');
                $('.trcrequest').html(loader3);
                $('.trcrequest').removeClass('btnloader');
            }
            startcountDown();
        });

    $('#credit_card').hide();
    $('#wiretransfer').hide();
    $('#paypal').hide();
    $('#paystack').hide();
    $('#bitcoin').hide();
    $('#trc20').show();
    $('#erc20').hide();
    $('#counter30').show(); //Counter 30s visible
    $('#btn-bitcoin').removeClass('selected-pay');
    $('#btn-erc20').removeClass('selected-pay');
    $('#btn-trc20').addClass('selected-pay');
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-paystack').removeClass('selected-pay');
    $('#btn-wire').removeClass('selected-pay');

    let utll = window.location.href;
    let addfundurl = utll.split('funds');
    let href = addfundurl[0] + 'funds/requestassettrc';
    $('form').attr('action', '');
    $('form').attr('action', href);
    $("#btnTrcc").attr('checked', true);

    $(".cardRadio").attr('hidden', true);
    $(".cardRadioInput").attr('hidden', false);  

    $("#btn-trc20 .cardRadioInput").attr('hidden', true);    
    $("#btn-trc20 .cardRadio").attr('hidden', false);
     return false;

});

$(document).on('click', '#btn-wire', function () {
    clearInterval(ct);
    $('#counter30').text('00:30' + ' ' + seconds);
    $('#credit_card').hide();
    $('#wiretransfer').show();
    $('#bitcoin').hide();
    $('#paystack').hide();
    $('#paypal').hide();
    $('#erc20').hide();
    $('#trc20').hide();
    $('#counter30').hide(); //Counter 30s hidden
    $('#btn-wire').addClass('selected-pay');
    $('#btn-visa').removeClass('selected-pay');
    $('#btn-paystack').removeClass('selected-pay');
    $('#btn-paypal').removeClass('selected-pay');
    $('#btn-bitcoin').removeClass('selected-pay');
    $('#btn-erc20').removeClass('selected-pay');
    $('#btn-trc20').removeClass('selected-pay');

    let utll = window.location.href;
    let addfundurl = utll.split('funds');
    let href = addfundurl[0] + 'funds/wirerequest';
    $('form').attr('action', '');
    $('form').attr('action', href);

    $(".cardRadio").attr('hidden', true);
    $(".cardRadioInput").attr('hidden', false);  

    $("#btn-wire .cardRadioInput").attr('hidden', true);    
    $("#btn-wire .cardRadio").attr('hidden', false);
 
   

});
$('#amount_other').hide();
$('#credit_card').show();
$('#wiretransfer').hide();
$('#paystack').hide();
$('#bitcoin').hide();
$('#paypal').hide();

$(document).on('change', '#amount_select', function () {
    if ($(this).val() == 'other') {
        $('#amount_other').show();
    } else {
        $('#amount_other').hide();
    }
    if ($('#btn-bitcoin').hasClass('selected-pay')) {
        $('.selected-pay').trigger('click');
    }
    if ($('#btn-erc20').hasClass('selected-pay')) {
        $('.selected-pay').trigger('click');
    }
    if ($('#btn-trc20').hasClass('selected-pay')) {
        $('.selected-pay').trigger('click');
    }
});

var timer, delay = 500;
$.get("https://ipinfo.io?token=8c7e3f86a5503d", function (response) {
    var city = response.city;
    var country = response.country;
    if (country == 'ng' || country == 'gh') {
        $('#btn-paystack').show();
    }
    if (country == 'ae' || country == 'AE') {
        $('#btn-visa').show();
        $('#btn-paystack').hide();
    }
});
$(document).on('input change', '#amount_select', function () {
    clearTimeout(timer);
    timer = setTimeout(function () {
        $.get("https://ipinfo.io?token=8c7e3f86a5503d", function (response) {
            var city = response.city;
            var country = response.country;
            if (country == 'ng' || country == 'gh') {
                $('#btn-paystack').show();
                $('#btn-visa').css('display', 'none');
            }
            if ($('#amount_select').val() != 'other' || $('#amount_other').val().trim() != '') {
                if ($('#amount_select').val() == 'other') {
                    var enteredAmount = $('#amount_other').val();
                } else {
                    var enteredAmount = $('#amount_select').val();
                }
            }
            if (country == 'ae' || country == 'AE') {
                var vat_valuea = 2.99;
            } else {
                var vat_valuea = 0;
            }
            if (country == 'ae' || country == 'AE') {
                var vat_value1 = 5;
            } else {
                var vat_value1 = 0;
            }
            document.getElementById("vat_value1").innerHTML = vat_value1 + "%";
            document.getElementById("vat_value2").innerHTML = vat_value1 + "%";
            document.getElementById("vat_value3").innerHTML = vat_value1 + "%";
            document.getElementById("vat_stack").innerHTML = vat_value1 + "%";
            enteredAmount = parseFloat(enteredAmount);
            if (enteredAmount < 100) {
                alert("Minimum amount is $100");
                return location.reload();
            }
            var amt = "$" + enteredAmount;
            var amt1 = $("#amt1").html(amt);
            var p1 = enteredAmount * 0.0299;
            var vat = enteredAmount * vat_valuea;
            var total1 = enteredAmount + p1 + vat;
            var t1 = "$" + total1;
            var totala1 = $("#totala1").html(t1);
            $("#amt2").html(amt);
            $("#card_amout1").html(amt);
            $("#process_fees").html('2.99%');
            var p2 = enteredAmount * 0.037;
            var total2 = enteredAmount + p2 + vat;
            var t2 = "$" + total2;
            var totala2 = $("#totala2").html(t2);
            var amt3 = $("#amt3").html(amt);
            var p3 = 0;
            var total3 = enteredAmount + p3 + vat;
            var t3 = "$" + total3;
            var totala3 = $("#totala3").html(t3);
            $(".wtcamount").html(t3);
            return totala1, totala2, totala3;
        }, "jsonp");
    }, delay);
})
function outFunc() {
    var tooltip = document.getElementById("walletTip");
    tooltip.innerHTML = "Click to copy";
}
function copyCode(containerid) {
    var range = document.createRange();
    range.selectNode(containerid); //changed here
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    $('.clickurl').addClass('select');
    setTimeout(function () {
        $('.clickurl').removeClass('select');
    }, 2000);
    var tooltip = document.getElementById("walletTip");
    tooltip.innerHTML = "Copied!";
}


const getpaylink = async () => {

    try {
        if ($('#amount_select').val() == 'other') {
            var amount = $('#amount_other').val();
        } else {
            var amount = $('#amount_select').val();
        }
        const ip = "121";
        const data = {
            amount: amount,
            ip: ip
        };

        let token = localStorage.getItem('user');
        const response = await axios.post(window.apiFetchBaseURL + 'payment-request', data, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        });
        if (response?.status === 200) { 
            localStorage.removeItem("tranRef");
            localStorage.removeItem("paymentmethod");
            localStorage.setItem("tranRef", JSON.stringify(response?.data?.data?.tran_ref));
            localStorage.setItem("paymentmethod", data.payment_method);

            window.location.replace(response?.data?.data?.redirect_url);
        }
    } catch (err) {
        console.log(err);
        if (axios?.isCancel(err)) {
            console.log("axios request cancelled");
        } else {
            if (err?.response?.status === 500) {
                Swal.fire({
                    icon: "error",
                    html: "There was an error communicating with the server! try again",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else if (err?.response?.status === 404) {
                Swal.fire({
                    icon: "error",
                    html: "An error occurred! Please try again",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else if (err?.response?.status === 401) {
                Swal.fire({
                    icon: "error",
                    html: "An error occurred! Please try again",
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    html: "There was an error communicating with the server! Try again.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }
    }
}
$(document).on('click', '#visapay', function () {
    $(".nav-fixed").addClass('onrequest');
    $(".dot-flashing").show();
    getpaylink();
})

$(document).on('click', '#showHide', function () {

  var x = document.getElementById("showHideSection");

  var chevronDown = document.getElementById("chevronDown");
  var chevronUp = document.getElementById("chevronUp");

  if (x.style.display === "none") {
    x.style.display = "block";

    chevronDown.style.display = "none";
    chevronUp.style.display = "block";

  } else {
    x.style.display = "none";

    chevronDown.style.display = "block";
    chevronUp.style.display = "none";
  }
})

