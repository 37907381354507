import React from "react";
import {Navigate,Outlet} from 'react-router-dom'

const privateComponent = ()=>{

    //const auth = sessionStorage.getItem('user');
    const auth = localStorage.getItem('publisherUser');
    return auth?<Outlet />:<Navigate to="/login" />
}

export default privateComponent;